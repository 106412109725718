
import { Component, Vue } from "vue-property-decorator";
import { Action, Mutation, Getter } from "vuex-class";
import { ISelectItem } from "@/types";
import { IPaymentTerm, IPaymentTermCreateRequest } from "@/types/payment_term";
import { PaymentTermCreateRequest } from "@/models/payment_term";
import { ICustomer, ICustomerListRequest } from "@/types/customer";
import {
  IPaymentMethod,
  IPaymentMethodListRequest
} from "@/types/payment_method";

@Component({})
export default class extends Vue {
  //payment_term
  @Action("payment_term/adminCreate")
  public create!: (params: IPaymentTermCreateRequest) => boolean;

  @Action("payment_term/adminIsStored")
  public isStored!: (params: { customer_id: number }) => number;

  @Getter("payment_term/single")
  public payment_term!: IPaymentTerm;

  @Mutation("payment_term/clear")
  public clearPaymentTerm!: () => void;

  //customer
  @Action("customer/adminGetList")
  public getCustomers!: (params: ICustomerListRequest) => boolean;

  @Getter("customer/selectItem")
  public customerList!: ISelectItem[];

  @Getter("customer/find")
  public findCustomer!: (id: number) => ICustomer;

  @Mutation("customer/clear")
  public clearCustomer!: () => void;

  //payment_method
  @Action("payment_method/adminGetList")
  public getPaymentMethods!: (request: IPaymentMethodListRequest) => boolean;

  @Getter("payment_method/find")
  public findPaymentMethod!: (id: number) => IPaymentMethod;

  @Getter("payment_method/selectItem")
  public paymentMethodList!: ISelectItem[];

  @Mutation("payment_method/clear")
  public clearPaymentMethod!: () => void;

  //パラメータ定義
  public params: IPaymentTermCreateRequest = new PaymentTermCreateRequest();

  //変数定義
  public customer_id = 0;
  public is_under_amount_status = 0;
  public close_eom = false;
  public submit_dialog = false;
  public valid = true;
  public lazy = false;

  //以上未満登録ステータス
  public is_under_amount_status_list = [
    { value: 1, text: "未登録" },
    { value: 2, text: "未満登録済" },
    { value: 3, text: "以上登録済" },
    { value: 4, text: "登録済" }
  ];

  public paymentMonthList = [
    { value: null, text: "指定無" },
    { value: 1, text: "翌月" },
    { value: 2, text: "翌々月" }
  ];

  //ルール定義
  public rules = {
    amount: [
      (v: string) =>
        !v || /^([1-9]\d*|0)(\.\d+)?$/.test(v) || "半角数字で入力してください",
      (v: string) => !v || /^([1-9]\d*|0)$/.test(v) || "整数で入力してください"
    ],
    is_under_amount: [(v: number) => v != null || "金額入力時は必須選択です"],
    payment_day: [
      (v: string) => !!v || "支払日は必須です",
      (v: string) =>
        !v ||
        /^(?:[1-9]|1\d|2\d|30|31)$/.test(v) ||
        "1~31日の間で入力してください"
    ],
    add_day: [
      (v: string) =>
        !v || /^([1-9]\d*|0)(\.\d+)?$/.test(v) || "半角数字で入力してください"
    ],
    customer_id: [(v: number) => !!v || "顧客は必須です"],
    payment_method_id: [(v: number) => !!v || "支払方法は必須です"]
  };

  //--------
  // コンポーネント作成時実行
  public async created() {
    this.params.customer_id = Number(this.$route.params.customer_id);
    this.clearPaymentTerm();
    this.clearCustomer();
    this.clearPaymentMethod();

    await Promise.all([
      this.getCustomers({ per_page: 0 }),
      this.getPaymentMethods({ per_page: 0 })
    ]);

    this.is_under_amount_status = await this.isStored({
      customer_id: this.params.customer_id
    });

    this.$nextTick(function () {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    });
  }

  // 登録確認画面
  public submitConfirm() {
    this.submit_dialog = true;
  }

  //--------
  // 確定ボタンが押された際の挙動
  // 更新時と作成時で問合せ先が違う
  public async submit() {
    this.submit_dialog = false;
    let result = false;

    result = await this.create(this.params);

    if (result) {
      this.$router.go(-1);
    }
  }

  //金額上下限を入力した際の挙動
  public amountInput() {
    if (!this.params.payment_terms[0].amount) {
      this.params.payment_terms[0].is_under_amount = null;
    }

    this.$nextTick(function () {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    });
  }

  //支払月を選択した際の挙動
  public paymentMonthChange() {
    if (!this.params.payment_terms[0].payment_month) {
      this.params.payment_terms[0].payment_day = null;
      this.close_eom = false;
    }

    this.$nextTick(function () {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    });
  }
}
